import { TELEPHONE_NUMBER } from './telephone';

export const GIVE_FEEDBACK = 'Give feedback';
export const LANGUAGE_KEY = 'language';
export const API_ERROR = 'Service currently unavailable. Please try again later or visit Member Services for help.';
export const CONFIRM_TEXT = 'Would you like us to request an associate to help you?';
export const THANKYOU_MSG = 'Thanks for visiting Sam`s Club!';

export const LOCATION = {
  JEWELRY: 'jewelry',
  ELECTRONICS: 'electronics',
  BACKROOM: 'backroom',
  MATTRESSES: 'mattresses',
  TIRE_N_BATTERY: 'tire',
  BAKERY: 'bakery',
  COSMETICS: 'cosmetics',
  TOYS: 'toys',
  INFANTS: 'infants',
  HBA: 'hba',
};

/** Content Fragments. Better alternative to avoid dangerouslySetInnerHTML */

export const CALL_HELP_DESK = (getString, qrId) => {
  const [, clubNumber] = qrId?.split?.('X') || [];
  const defaultPhoneNumber = '(000) 000-0000';
  const helpLineNumber = TELEPHONE_NUMBER[Number(clubNumber)] || defaultPhoneNumber;
  return (
    <>
      {getString('call_help_desk')}
      {' '}
      <a href={`tel:${helpLineNumber}`} className="helpdesk__phone">{helpLineNumber}</a>
    </>
  );
};
