import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { WorldIcon, WorldIconBlue } from '../../assets/img';
import './styles.css';
import { LOCALES } from '../../i18n';

export default function LanguageSettings({
  currentLocale,
  changeLocale,
  showWhite = true,
  isCA,
}) {
  const intl = useIntl();
  const otherLocale = isCA ? LOCALES.FRENCH : LOCALES.SPANISH;
  const toggleLocale = () => {
    changeLocale(currentLocale === otherLocale ? LOCALES.ENGLISH : otherLocale);
  };

  const getLocaleStr = (id) => intl.formatMessage({ id });

  return (
    <div className="row language-settings">
      <button type="button" className="row roboto language-settings button-with-no-border" onClick={toggleLocale}>
        <b className={`language ${showWhite ? 'color-white' : 'color-blue'}`}>
          {getLocaleStr(isCA ? 'default_language_ca' : 'default_language')}
        </b>
        <img src={showWhite ? WorldIcon : WorldIconBlue} alt="WorldIcon" />
      </button>
    </div>
  );
}

LanguageSettings.propTypes = {
  showWhite: PropTypes.bool.isRequired,
  currentLocale: PropTypes.string.isRequired,
  changeLocale: PropTypes.func.isRequired,
  isCA: PropTypes.bool.isRequired,
};
