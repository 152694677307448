import { isObject, convertMstoMinutes } from './functionalUtils';

/**
 * @name preparePayloadForStorage
 * @param {String} qrLocation Location of the QR code
 * @param {String} requestState
 * @param {String} associateName
 * @returns {Object} Payload to be saved in the localStorage
 */
export const preparePayloadForStorage = (qrLocation, requestState, transactionId, timestamp, eventTimestamp, associateName = '') => ({
  qrLocation,
  requestState,
  transactionId,
  associateName,
  timestamp: timestamp || Date.now(),
  eventTimestamp,
});

/**
 * @name isActivePrevState
 * @param {Object} prevState
 * @returns {Boolean}
 */
export const isActivePrevState = (prevState = {}) => {
  if (!isObject(prevState)) return false;
  const isValidPrevState = Object.keys(preparePayloadForStorage()).every((key) => key in prevState);
  if (!isValidPrevState) return false;
  if (convertMstoMinutes(Date.now() - prevState.timestamp) >= 2) return false;
  return true;
};

/**
 * @name prepareStateObj
 * @param {String} qrId
 * @param {Object} otherData
 * @returns {Object}
 */
export const prepareStateObj = (qrId, isDuplicateRequest = false, otherData = {}) => ({ qrId, isDuplicateRequest, ...otherData });
