import ReactFloaterJs from 'react-floaterjs';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { MainLogo, Shape, WalmartBlueLogo } from '../../assets/img';
import { Placeholder, CLUB_IDENTIFIER } from '../../constants/constants';
import './styles.css';

const ThanksPage = (props) => {
  const intl = useIntl();
  const getLocaleStr = (id) => intl.formatMessage({ id });
  const getContent = (key, value) => <>{getLocaleStr(key).replace(Placeholder, value)}</>;
  const { club, clubLogo } = props.isCA ? { club: CLUB_IDENTIFIER.Walmart, clubLogo: WalmartBlueLogo } : { club: CLUB_IDENTIFIER.SamsClub, clubLogo: Shape };

  return (
    <div className="wrapper">
      <div id="upper" className="upperDiv">
        <div className="sams-logo__container">
          <img src={clubLogo} className="Shape" alt="Logo" />
        </div>
        <ReactFloaterJs>
          <img src={MainLogo} className="mainLogo" alt="Fled" />
        </ReactFloaterJs>
        <span className="primaryText">
          {getContent('thankyou_msg', club)}
        </span>
      </div>
      <div id="lower" className="lowerDiv" />
    </div>
  );
};

ThanksPage.propTypes = {
  isCA: PropTypes.bool.isRequired,
};

export default ThanksPage;
