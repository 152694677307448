import { useState, useEffect } from 'react';

import { getTimeout } from './utils';
import { getAcknowledgementUpdates } from '../../utils/httpUtils';
import {
  convertMinutesToMs,
  deserialiseData,
  getValueFromBrowserStorage,
  prepareStateObj,
} from '../../utils';
import {
  REQUEST_STATES,
  ACK_STATUS,
  ESCALATION_TIMEOUT,
  REQUEST_TIMEOUT,
  ENVS,
} from '../../constants/constants';

/**
 * @name useAssociateAckDetails
 * @type CustomHook
 * @param {String} transactionId Unique transactionId for the request
 * @param {Function} setRequestState Set state function from the component
 * @description To connect with AANS long polling API to retrieve ack status and associate details
 */
export const useAssociateAckDetails = (transactionId, setRequestState, requestStateAfterAck) => {
  const [associateName, setAssociateName] = useState('');

  useEffect(() => {
    getAcknowledgementUpdates(transactionId)
      .then((response) => {
        const { status, associateDetails } = response;
        if (status === ACK_STATUS.ACKNOWLEDGED) {
          setAssociateName(associateDetails.associateName);
          setRequestState(requestStateAfterAck);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(`Error connecting with the upstream ${err.message}`);
      });
  }, []);

  return [associateName];
};

/**
 * @name useReqTimeout
 * @type CustomHook
 * @param {String} requestState constant value from REQUEST_STATES
 * @param {Function} setRequestState Set state function from the component
 * @param {Number} previousTimestamp Original request timestamp
 * @description To show escalated state and request timeout state to the member in case of no acknowledgement
 */
export const useReqTimeout = (requestState, setRequestState, previousTimestamp) => {
  const escalationTimeout = getTimeout(ESCALATION_TIMEOUT, previousTimestamp);
  const requestTimeout = getTimeout(REQUEST_TIMEOUT - ESCALATION_TIMEOUT, previousTimestamp);

  useEffect(() => {
    const escalationTimer = setTimeout(() => {
      if (requestState === REQUEST_STATES.Pending) setRequestState(REQUEST_STATES.Escalated);
    }, convertMinutesToMs(escalationTimeout));

    const requestTimer = setTimeout(() => {
      if (requestState === REQUEST_STATES.Escalated) setRequestState(REQUEST_STATES.Still_Looking);
    }, convertMinutesToMs(requestTimeout));

    if ([REQUEST_STATES.Still_Looking, REQUEST_STATES.On_The_Way, REQUEST_STATES.Trck_Delivery_On_The_Way].includes(requestState)) {
      clearTimeout(escalationTimer);
      clearTimeout(requestTimer);
    }

    return () => {
      clearTimeout(escalationTimer);
      clearTimeout(requestTimer);
    };
  }, [requestState]);
};

/**
 * @name useBrowserUnloadActions
 * @type CustomHook
 * @param {Object} history browser History object
 * @param {Object} location browser Location object
 * @param {String} qrId QR code id
 * @param {String} transactionId Unique transactionId for the request
 * @description To save the previous help needed request details when member tries to refresh the page
 */
export const useBrowserUnloadActions = (history, location, qrId, transactionId) => {
  useEffect(() => {
    if (process.env.NODE_ENV === ENVS.production) {
      const saveSessionCache = () => {
        const prevReqPayload = deserialiseData(getValueFromBrowserStorage(qrId, localStorage));
        const data = { ...prevReqPayload, transactionId, isDuplicateRequest: true };
        history.replace({ ...location, state: prepareStateObj(qrId, false, data) });
      };

      window.onbeforeunload = saveSessionCache;
      window.onunload = saveSessionCache; // works correctly on android chrome

      return () => {
        window.onbeforeunload = () => {};
        window.onunload = () => {};
      };
    }
  }, []);
};
