import PropTypes from 'prop-types';

function StarIcon({ fill, size }) {
  return (
    <svg
      width={size}
      height={size}
      fill={fill}
    >
      {size > 40
        ? (
          <path
            xmlns="http://www.w3.org/2000/svg"
            d={`M21.8458 36.0824L22.6277 37.3625L21.8458 36.0824L10.4118 43.0663C10.3609 43.0974 10.3317 43.0978 10.3154 43.0967C10.2908 
            43.0951 10.2554 43.0849 10.2191 43.0586C10.1828 43.0322 10.1622 43.0017 10.1531 42.9788C10.147 42.9636 10.1384 42.9357 10.1522 
            42.8777L13.261 29.8452C13.5371 28.6876 13.1424 27.4727 12.2386 26.6985L2.0632 17.9822C2.01793 17.9434 2.00852 17.9158 2.00449 
            17.9C1.99841 17.8761 1.99713 17.8393 2.01099 17.7966C2.02485 17.7539 2.04755 17.7249 2.06648 17.7092C2.07906 17.6987 2.10291 
            17.6819 2.16233 17.6771L15.5177 16.6064C16.704 16.5113 17.7374 15.7605 18.1944 14.6617L23.3397 2.29085C23.3626 2.23581 23.386 
            2.21832 23.3998 2.20959C23.4206 2.19644 23.4553 2.18384 23.5001 2.18384C23.545 2.18384 23.5796 2.19644 23.6004 2.20959C23.6143 
            2.21832 23.6376 2.2358 23.6605 2.29085L28.8058 14.6617C29.2629 15.7605 30.2963 16.5113 31.4826 16.6064L44.8379 17.6771C44.8973 
            17.6819 44.9212 17.6987 44.9338 17.7092C44.9527 17.7249 44.9754 17.7539 44.9893 17.7966C45.0031 17.8393 45.0018 17.8761 44.9958 
            17.9C44.9917 17.9158 44.9823 17.9434 44.937 17.9822L34.7617 26.6985C33.8578 27.4727 33.4631 28.6876 33.7393 29.8452L36.848 
            42.8777C36.8618 42.9357 36.8532 42.9636 36.8471 42.9788C36.838 43.0017 36.8174 43.0322 36.7811 43.0586C36.7448 43.085 36.7094 43.0951 
            36.6848 43.0967C36.6685 43.0978 36.6393 43.0974 36.5885 43.0663L25.1544 36.0824L24.3726 37.3625L25.1544 36.0824C24.1388 35.4621 22.8614 
            35.4621 21.8458 36.0824Z`}
            stroke="#BF8921"
            strokeWidth="3"
          />
        )
        : (
          <path
            xmlns="http://www.w3.org/2000/svg"
            d={`M8.38053 7.16572L10.3662 2.39158L12.3519 7.16572L13.2752 6.78169L12.3519 7.16573C12.6007 7.76392 13.1632 8.17264 13.809 
            8.22441L18.9631 8.63761L15.0363 12.0014C14.5442 12.4229 14.3294 13.0842 14.4797 13.7144L15.6794 18.7439L11.2668 16.0487C10.7139 
            15.711 10.0185 15.711 9.46564 16.0487L9.98689 16.9021L9.46564 16.0487L5.05302 18.7439L6.25274 13.7144C6.40307 13.0842 6.18819 
            12.4229 5.69616 12.0014L1.76929 8.63761L6.92337 8.22441C7.56917 8.17264 8.13173 7.76392 8.38053 7.16572ZM19.4603 8.21171L19.46 8.21201L19.4603 8.21171Z`}
            stroke="#BF8921"
            strokeWidth="2"
          />
        )}
    </svg>
  );
}

StarIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
};

StarIcon.defaultProps = {
  fill: 'none',
  size: '21',
};

export default StarIcon;
