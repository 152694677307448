import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CannedFeedback from '../CannedFeedback';
import FeedbackIconSet from './FeedbackIconSet';
import * as Constants from '../../constants/constants';
import './styles.css';
import '../../global/typography.css';

const Feedback = (props) => {
  const {
    allowUserSelection,
    numberOfStars,
    titleStyle,
    starSize,
    onRatingSelect,
    onClick,
    feedbackReaction,
    feedbackListOptions,
    onFeedbackSubmit,
    getLocaleStr,
    title,
  } = props;

  const starsMap = [...Array(numberOfStars)];
  const [starRating, setStarRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [feedbackListSelections, setFeedbackListSelections] = useState([]);
  const [feedbackText, setFeedbackText] = useState('');

  const onMouseEnter = (index) => setHoverRating(index);

  const onMouseLeave = () => setHoverRating(0);

  const toggleFeedbackListSelections = (listId) => {
    const selectedListSet = new Set(feedbackListSelections);
    if (selectedListSet.has(listId)) selectedListSet.delete(listId);
    else selectedListSet.add(listId);
    setFeedbackListSelections(Array.from(selectedListSet));
  };

  const onFeedbackRatingSelection = (rating) => {
    setStarRating(rating);
    onRatingSelect(rating);
  };

  const onFeedbackTextInput = (e) => setFeedbackText(e.target.value);

  const _onFeedbackSubmit = () => {
    if (starRating) {
      const cannedResponses = feedbackListOptions
        .filter((option) => feedbackListSelections.includes(option.id))
        .map((option) => option.title);
      onFeedbackSubmit(starRating, cannedResponses, feedbackText);
    }
  };

  useEffect(() => {
    setFeedbackListSelections([]);
  }, [feedbackListOptions]);

  const feedbackMaxLengthCSS = 'roboto typography-regular font-400 fb-max-length '.concat(feedbackText.length === Constants.FEEDBACK_MAX_LENGTH ? 'red' : 'gray');
  const feedbackLengthRemaining = feedbackText.length > Constants.FEEDBACK_MAX_LENGTH ? 0 : (Constants.FEEDBACK_MAX_LENGTH - feedbackText.length);

  return (
    <>
      <div className="feedback" onClick={onClick} role="button" tabIndex={0}>
        <span className={`feedback-title ${titleStyle}`}>{title}</span>
        <div className="feedback-stars">
          {starsMap.map((value, index) => {
            const i = index + 1;
            return (
              <FeedbackIconSet
                key={`star_${i}`}
                index={i}
                rating={starRating}
                starSize={starSize}
                hoverRating={hoverRating}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onRatingSelection={onFeedbackRatingSelection}
                allowUserSelection={allowUserSelection}
              />
            );
          })}
        </div>
      </div>
      {allowUserSelection && (
        <>
          <span className="roboto feedback-reaction typography-regular font-500">{feedbackReaction}</span>
          <CannedFeedback
            list={feedbackListOptions}
            selectedOptions={feedbackListSelections}
            toggleListSelection={toggleFeedbackListSelections}
          />
          <div id="feedback-text" className="feedback-input">
            <textarea
              data-testid="feedback-text"
              value={feedbackText}
              onChange={onFeedbackTextInput}
              className="roboto typography-regular feedback-input-textarea font-400"
              rows="15"
              maxLength={Constants.FEEDBACK_MAX_LENGTH}
              placeholder={getLocaleStr('tell_us_what_you_liked')}
            />
            <span className={feedbackMaxLengthCSS}>{`${feedbackLengthRemaining} characters left`}</span>
          </div>
          <button
            type="button"
            onClick={_onFeedbackSubmit}
            className="roboto font-500 typography-regular button feedback-send"
          >
            {getLocaleStr('buttons.send')}
          </button>
        </>
      )}
    </>
  );
};

Feedback.propTypes = {
  allowUserSelection: PropTypes.bool,
  numberOfStars: PropTypes.number,
  titleStyle: PropTypes.string,
  starSize: PropTypes.string,
  onRatingSelect: PropTypes.func,
  onClick: PropTypes.func,
  feedbackReaction: PropTypes.string,
  feedbackListOptions: PropTypes.instanceOf(Array),
  onFeedbackSubmit: PropTypes.func,
  getLocaleStr: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

Feedback.defaultProps = {
  allowUserSelection: false,
  numberOfStars: 5,
  titleStyle: '',
  starSize: '',
  onRatingSelect: () => { },
  onClick: () => { },
  onFeedbackSubmit: () => { },
  feedbackReaction: '',
  feedbackListOptions: [],
};

export default Feedback;
