import { createPortal } from 'react-dom';
import './styles.css';

const Portal = ({ children }) => createPortal(
  <div className="portal_container">
    <div className="portal_modal">{children}</div>
  </div>,
  document.getElementById('portal'),
);

export default Portal;
