/* eslint-disable no-restricted-globals, no-alert */
import { QR_LOCATIONS } from '../../constants/constants';
import { ERROR_PAGE, CLUB_CLOSED } from '../../constants/routes';
import {
  deserialiseData,
  getLocationParam,
  isActivePrevState,
  getValueFromBrowserStorage,
  triggerHelpNeededNotification,
  prepareStateObj,
  doesStringMatch,
} from '../../utils';

/**
 * @name triggerHelpNotification
 * @param {String} qrId
 * @param {Object} history
 * @param {Boolean} isCA
 */
export const triggerHelpNotification = (qrId, history, isCA = false) => {
  triggerHelpNeededNotification(qrId)
    .then((response) => {
      const {
        redirectionUri,
        transactionId,
        eventTimestamp,
        location,
        clubClosed,
        closureReason,
        closureMessage,
        rateLimitReached,
      } = response;
      if (rateLimitReached) {
        history.push(ERROR_PAGE, rateLimitReached);
      } else if (clubClosed) {
        history.push(CLUB_CLOSED, { closureReason, closureMessage, isBackroom: doesStringMatch(location, QR_LOCATIONS.Backroom) });
      } else {
        history.push(redirectionUri, prepareStateObj(qrId, false, { transactionId, eventTimestamp }));
      }
    })
    .catch(() => {
      history.push(ERROR_PAGE, isCA ? 'api_error_ca' : 'api_error');
    });
};

/**
 * @name checkDuplicateRequestAndRedirect
 * @description Check for duplicate requests within 2 minutes timeframe
 * @param {String} qrId
 * @param {Object} history
 * @returns {Boolean} Whether it is a duplicate request
 */
export const checkDuplicateRequestAndRedirect = (qrId, history) => {
  const previousState = deserialiseData(getValueFromBrowserStorage(qrId, localStorage));
  const pathParam = getLocationParam(previousState.qrLocation);
  if (pathParam && isActivePrevState(previousState)) {
    history.push(pathParam, prepareStateObj(qrId, true, previousState));
    return true;
  }
  return false;
};
