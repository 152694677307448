import { Placeholder } from './constants';

// API Endpoints
export const TRIGGER_NOTIFICATION = `/api/triggerNotification/${Placeholder}`;
export const QR_ACK_ENDPOINT = `/api/polling/acknowledgement-updates/${Placeholder}`;
export const SUBMIT_FEEDBACK = '/api/ratingReceived';

// Browser Routes
export const HOME = '/';
export const HELP_NEEDED_REQUEST = '/api/help/:id';
export const HELP_ON_THE_WAY = '/help-on-the-way';
export const TRUCK_ARRIVAL = '/truck-arrival';
export const ERROR_PAGE = '/error';
export const CLUB_CLOSED = '/club-closed';
export const THANKS = '/thank-you';
