export const LOCALES = {
  ENGLISH: 'en-US',
  JAPANESE: 'ja-JA',
  FRENCH: 'fr-FR',
  GERMAN: 'de-DE',
  SPANISH: 'es-ES',
};

export const messages = {
  [LOCALES.ENGLISH]: require('../strings/en.json'),
  [LOCALES.SPANISH]: require('../strings/es.json'),
  [LOCALES.FRENCH]: require('../strings/fr.json'),
};
