import { memo } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import '../../global/typography.css';

function CannedFeedback(props) {
  const { list, selectedOptions, toggleListSelection } = props;

  const lisItemStyle = (listId) => `roboto font-500 typography-regular can-item ${selectedOptions?.includes(listId) ? 'active-background' : 'inactive-background'}`;

  return (
    <div className="list-container">
      <div aria-label="canned-feedback" className="can-item-container">
        {list?.map((cannedFeedback) => (
          <button
            className={lisItemStyle(cannedFeedback.id)}
            data-testid={cannedFeedback.id}
            key={cannedFeedback.id}
            onClick={() => toggleListSelection(cannedFeedback.id)}
            type="button"
          >
            {cannedFeedback?.title}
          </button>
        ))}
      </div>
    </div>
  );
}

CannedFeedback.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  toggleListSelection: PropTypes.func.isRequired,
};

CannedFeedback.defaultProps = {
  selectedOptions: [],
};

export default memo(CannedFeedback);
