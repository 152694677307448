import PropTypes from 'prop-types';

import * as contents from '../../constants/contents';
import { Close } from '../../assets/img';
import './styles.css';
import '../../global/typography.css';

const Modal = ({
  children, title, size, onClose,
}) => {
  const setSizeClass = () => {
    switch (size) {
      case 'stretch':
        return 'modal-full';
      default:
        return '';
    }
  };

  return (
    <div className="modal">
      <div className={`modal-content ${setSizeClass()}`}>
        <div className="modal-header">
          <span className="roboto typography-large font-500">{title}</span>
          <div className="close-icon" role="button" tabIndex="0" onClick={onClose}>
            <img src={Close} alt="Close" />
          </div>
        </div>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer" />
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  title: contents.GIVE_FEEDBACK,
  size: 'stretch',
};

export default Modal;
