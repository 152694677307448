import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { checkDuplicateRequestAndRedirect, triggerHelpNotification } from './utils';
import './styles.css';

const HelpRequest = (props) => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!checkDuplicateRequestAndRedirect(id, history)) {
      triggerHelpNotification(id, history, props.isCA);
    }
  }, []);

  return (
    <div className="loader__container">
      <div className="loader" />
    </div>
  );
};

HelpRequest.propTypes = {
  isCA: PropTypes.bool.isRequired,
};
export default HelpRequest;
