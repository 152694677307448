import * as Strings from '../../constants/contents';
import {
  Search,
  Person,
  Info,
  HelpDesk,
  PersonPlus,
} from '../../assets/img';
import {
  REQUEST_STATES,
  Placeholder,
  QR_LOCATIONS,
} from '../../constants/constants';
import { doesStringMatch } from '../../utils';

import {
  Conversation, Escalated, TruckConversation, SearchingAssociate,
} from '../../assets/lottie';

// To map the Icon as per requestStates
export const SmallIconMap = {
  [REQUEST_STATES.Pending]: Search,
  [REQUEST_STATES.Escalated]: PersonPlus,
  [REQUEST_STATES.On_The_Way]: Person,
  [REQUEST_STATES.Trck_Delivery_On_The_Way]: Person,
  [REQUEST_STATES.Still_Looking]: Info,
};

// To map the Icon as per requestStates
export const CoverImageMap = {
  [REQUEST_STATES.Pending]: { data: SearchingAssociate, animate: true },
  [REQUEST_STATES.Still_Looking]: { data: HelpDesk },
  [REQUEST_STATES.On_The_Way]: { data: Conversation, animate: true },
  [REQUEST_STATES.Trck_Delivery_On_The_Way]: { data: TruckConversation, animate: true },
  [REQUEST_STATES.Escalated]: { data: Escalated, animate: true },
};

/**
 * @name getDisplayNameForLocation
 * @param {function} getLocaleStr i18n Function to retrieve localise strings
 * @param {String} location The QR location
 * @returns {String} Location Display Name
 */
export const getDisplayNameForLocation = (getLocaleStr, location) => {
  switch (String(location)?.trim()?.toLowerCase()) {
    case Strings.LOCATION.JEWELRY:
    case Strings.LOCATION.ELECTRONICS: return getLocaleStr(location.toLowerCase());
    case Strings.LOCATION.BACKROOM:
    case Strings.LOCATION.BAKERY:
    case Strings.LOCATION.MATTRESSES: return getLocaleStr(location.toLowerCase());
    case Strings.LOCATION.TIRE_N_BATTERY: return getLocaleStr('tire_n_battery');
    case Strings.LOCATION.TOYS: return getLocaleStr(location.toLowerCase());
    case Strings.LOCATION.COSMETICS: return getLocaleStr(location.toLowerCase());
    case Strings.LOCATION.INFANTS: return getLocaleStr(location.toLowerCase());
    case Strings.LOCATION.HBA: return getLocaleStr(location.toLowerCase());
    default: return location;
  }
};

/**
 * @name getTitleAndSupportiveText
 * @param {function} getLocaleStr i18n Function to retrieve localise strings
 * @param {String} requestState The current help requestState
 * @param {String} associateName Associate who acknowledged the request
 * @param {String} location The QR location
 * @param {Boolean} isCA is CA Domain
 * @returns {Object} Object contains title text and supportiveText
 */
export const getTitleAndSupportiveText = (getLocaleStr, requestState = '', associateName = '', location = '', qrId = '', isCA = false) => {
  let titleText = '';
  let renderSupportiveText = () => '';
  const isBackroom = doesStringMatch(location, QR_LOCATIONS.Backroom);
  const displayLocation = getDisplayNameForLocation(getLocaleStr, location);
  const getContent = (key, value) => <>{getLocaleStr(key).replace(Placeholder, value)}</>;

  switch (requestState) {
    case REQUEST_STATES.Pending: {
      titleText = getLocaleStr('finding_an_associate');
      renderSupportiveText = () => getContent('will_meet_at_location', displayLocation);
      break;
    }

    case REQUEST_STATES.Escalated: {
      titleText = getLocaleStr(isBackroom ? 'driver_still_finding_help' : 'on_the_way_asap');
      const supportiveText = isBackroom ? Strings.CALL_HELP_DESK(getLocaleStr, qrId) : getContent('thanks_for_waiting', displayLocation);
      renderSupportiveText = () => supportiveText;
      break;
    }

    case REQUEST_STATES.Trck_Delivery_On_The_Way:
    case REQUEST_STATES.On_The_Way: {
      titleText = getContent(isCA ? 'associate_on_the_way_ca' : 'associate_on_the_way', associateName);
      renderSupportiveText = () => getContent('see_you_soon', displayLocation);
      break;
    }

    case REQUEST_STATES.Still_Looking: {
      titleText = getLocaleStr('with_other_members');
      renderSupportiveText = () => (isBackroom ? Strings.CALL_HELP_DESK(getLocaleStr, qrId) : getLocaleStr(isCA ? 'try_member_service_desk_ca' : 'try_member_service_desk'));
      break;
    }

    default: break;
  }

  return { titleText, renderSupportiveText };
};

/**
 * @name getInitialRequestTimestamp
 * @param {Boolean} isDuplicateRequest to indicate whether it is a duplicate request
 * @param {Number | String} previousTimestamp Previous request timestamp value
 * @returns {Date} Appropriate timestamp value
 */
export const getInitialRequestTimestamp = (isDuplicateRequest = false, previousTimestamp) => {
  if (!isDuplicateRequest) return Date.now();
  if (!Number.isNaN(+previousTimestamp)) return +previousTimestamp;
  return Date.now();
};

/**
 * @name getTimeout
 * @param {Number} previousTimestamp
 * @returns {Number} timeout
 */
export const getTimeout = (timeout, previousTimestamp = 0) => {
  const prevTimestamp = +previousTimestamp;
  if (Number.isNaN(prevTimestamp)) return timeout;
  const diff = Math.floor((Date.now() - prevTimestamp) / (1000 * 6)) / 10;
  if (diff <= timeout) return timeout - diff;
  return timeout;
};
