/**
 * @name convertMinutesToMs
 * @description Converts minutes into milliseconds
 * @param {Number} minutes
 * @returns {Number}
 */
export const convertMinutesToMs = (minutes = 1) => minutes * 60 * 1000;

/**
 * @name convertMstoMinutes
 * @description Converts milliseconds to Minutes
 * @param {Number} ms
 * @returns {Number}
 */
export const convertMstoMinutes = (ms) => Math.floor(ms / (1000 * 60));

/**
 * @name getQueryParam
 * @param {String} queryString
 * @param {String} param
 * @returns {String} Query param value
 */
export const getQueryParam = (queryString, param) => new URLSearchParams(queryString).get(param);

/**
 * @name isObject
 * @param {Any} input
 * @returns {Boolean} Checks whether the given input is an object
 */
export const isObject = (input) => typeof input === 'object' && input !== null && !Array.isArray(input);

/**
 * @name getLocationParam
 * @param {String} qrLocation
 * @returns {String}
 */
export const getLocationParam = (qrLocation) => (qrLocation ? `/?location=${qrLocation}` : '');

/**
  * @name doesObjHasKeys
  * @param {Any} input
  * @returns {Boolean} whether the passed input is an object and it is not empty
  */
export const doesObjHasKeys = (input) => isObject(input) && !!Object.keys(input).length;

/**
 * @name deserialiseData
 * @param {String} data
 * @returns {Object}
 */
export const deserialiseData = (data) => {
  try {
    const obj = JSON.parse(data);
    return isObject(obj) ? obj : {};
  } catch {
    return {};
  }
};

/**
 * @name doesStringMatch
 * @param {String} str1
 * @param {String} str2
 * @param {Boolean} ignoreCase
 * @returns {Boolean}
 */
export const doesStringMatch = (str1 = '', str2 = '', ignoreCase = true) => {
  if (ignoreCase) return str1?.toLocaleLowerCase() === str2?.toLowerCase();
  return str1 === str2;
};

/**
 * @name createMarkup
 * @param {String} htmlText
 * @returns {Object}
 */
export const createMarkup = (htmlText) => ({
  __html: htmlText,
});
