export const ESCALATION_TIMEOUT = 1;
export const REQUEST_TIMEOUT = 2;
export const FEEDBACK_MAX_LENGTH = 300;

export const KEY_FEEDBACK_SUBMITTED = 'feedbackSubmitted';

export const COUNTRY_IDENTIFIER = 'walmart.ca';

export const CLUB_IDENTIFIER = {
  Walmart: 'Walmart',
  SamsClub: 'Sam`s Club',
};

export const ENVS = {
  production: 'production',
  development: 'development',
  test: 'test',
};

export const LABELS = {
  DEVICE_ID: 'deviceId',
  TRANSACTION_ID: 'transactionId',
  LOCATION: 'location',
};

export const QR_LOCATIONS = {
  Backroom: 'Backroom',
};

export const CLUB_CLOSURE_REASONS = {
  Emergency: 'Emergency',
  Holiday: 'Holiday',
  OutOfHours: 'OutOfHours',
};

export const FEEDBACK_OPTIONS = (localize) => ({
  1: [
    { id: '1', title: localize('no_one_showed_up') },
    { id: '2', title: localize('not_friendly') },
    { id: '3', title: localize('not_helpful') },
  ],
  2: [
    { id: '1', title: localize('long_wait') },
    { id: '2', title: localize('not_friendly') },
    { id: '3', title: localize('not_helpful') },
  ],
  3: [
    { id: '1', title: localize('friendly') },
    { id: '2', title: localize('helpful') },
  ],
  4: [
    { id: '1', title: localize('short_wait') },
    { id: '2', title: localize('friendly') },
    { id: '3', title: localize('helpful') },
  ],
  5: [
    { id: '1', title: localize('short_wait') },
    { id: '2', title: localize('friendly') },
    { id: '3', title: localize('above_and_beyond') },
  ],
});

export const ERROR_STATUS = {
  Rate_Limited: 429,
  Request_Timeout: 408,
};

export const Placeholder = '{s}';

export const REQUEST_STATES = {
  Pending: 'Pending',
  Escalated: 'Escalated',
  On_The_Way: 'On the way',
  Trck_Delivery_On_The_Way: 'Truck Delivery On the way',
  Still_Looking: 'Still Looking',
};

export const ACK_STATUS = {
  PENDING: 'PENDING',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
};
