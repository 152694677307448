import { useEffect, useRef } from 'react';
import { Tick } from '../../assets/img';
import PropTypes from 'prop-types';
import './styles.css';
import '../../global/typography.css';

export default function Toast({ message, onHide }) {
  let timerTask;
  const animated = useRef(null);

  useEffect(() => {
    clearTimeout(timerTask);
    timerTask = setTimeout(() => {
      animated?.current?.addEventListener('animationend', () => {
        onHide && onHide(false);
      });
    }, 5000);
  }, [animated?.current]);

  return (
    <div ref={animated} id="slide" className="toast-container">
      <div className="tick-container">
        <img src={Tick} className="tick" alt="toast-icon" />
      </div>
      <p className="font-500 typography-regular">{message}</p>
    </div>
  );
}

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};
