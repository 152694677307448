import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GenericErrorImage } from '../../assets/img';
import './styles.css';

const GenericErrorPage = (props) => {
  const location = useLocation();
  const intl = useIntl();

  const getLocaleStr = (id) => intl.formatMessage({ id });
  const errorText = props.isCA ? 'too_many_request_ca' : 'too_many_request';

  return (
    <div className="error__container">
      <img src={GenericErrorImage} alt="error_img" />
      <div className="roboto error__text">
        {getLocaleStr(location?.state || errorText)}
      </div>
    </div>
  );
};

GenericErrorPage.propTypes = {
  isCA: PropTypes.bool.isRequired,
};

export default GenericErrorPage;
