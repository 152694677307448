import { useMemo } from 'react';
import PropTypes from 'prop-types';
import StarIcon from './StarIcon';

function FeedbackIconSet({
  index,
  rating,
  starSize,
  hoverRating,
  onMouseEnter,
  onMouseLeave,
  onRatingSelection,
  allowUserSelection,
}) {
  const fill = useMemo(() => {
    if (hoverRating >= index || (!hoverRating && rating >= index)) {
      return '#FFB72C';
    }
    return 'none';
  }, [rating, hoverRating, index]);

  return (
    <div
      className="star-wrapper"
      role="button"
      tabIndex={0}
      onMouseEnter={() => allowUserSelection && onMouseEnter(index)}
      onMouseLeave={() => allowUserSelection && onMouseLeave()}
      onClick={() => allowUserSelection && onRatingSelection(index)}
    >
      <StarIcon fill={fill} size={starSize} />
    </div>
  );
}

FeedbackIconSet.propTypes = {
  index: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
  hoverRating: PropTypes.number.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onRatingSelection: PropTypes.func.isRequired,
  allowUserSelection: PropTypes.bool.isRequired,
  starSize: PropTypes.string.isRequired,
};

export default FeedbackIconSet;
