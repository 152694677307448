import { CLUB_CLOSURE_REASONS, Placeholder } from '../../constants/constants';
import { HolidayIcon, OutOfHoursIcon } from '../../assets/img';
/**
 * @name getClubClosureIconAndText
 * @param {Obect} state state object from useLocation
 * @param {function} get locale string by key from language files
 * @returns {Object} icon and text details
 */
export const getClubClosureIconAndText = (state = {}, getLocaleStr) => {
  const { closureReason, closureMessage, isBackroom } = state;

  let icon;
  let text;

  if (isBackroom) {
    switch (closureReason) {
      case CLUB_CLOSURE_REASONS.Holiday: {
        icon = HolidayIcon;
        text = getLocaleStr('club_closed_holiday').replace(Placeholder, closureMessage);
        break;
      }
      default: {
        icon = OutOfHoursIcon;
        text = getLocaleStr('club_closed_out_of_office_hours').replace(Placeholder, closureMessage);
        break;
      }
    }
  } else {
    icon = OutOfHoursIcon;
    text = getLocaleStr('club_closed_generic');
  }
  return { icon, text };
};
