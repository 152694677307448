import { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { Shape, TruckDelivery } from '../../assets/img';
import * as ROUTES from '../../constants/routes';
import './styles.css';

const TruckArrival = () => {
  const [shipmentId, setShipmentId] = useState('');
  const [trailerId, setTrailerId] = useState('');

  const shipmentIdFieldRef = useRef();
  const trailerIdFieldRef = useRef();

  const history = useHistory();

  const onUpdateShipmentId = (event) => {
    event.stopPropagation();
    setShipmentId(event.target.value);
  };

  const onUpdateTrailerId = (event) => {
    event.stopPropagation();
    setTrailerId(event.target.value);
  };

  const onSubmit = () => {
    if (shipmentId || trailerId) {
      history?.push(ROUTES.HOME);
    }
  };

  return (
    <div className="wrapper bg-blue">
      <div className="sams-logo dispaly-center bg-white">
        <img src={Shape} className="Shape" alt="Logo" />
      </div>
      <div className="img-wrapper dispaly-center bg-white">
        <img src={TruckDelivery} width="500" height="300" className="truck-delivery" alt="Truck Delivery Animation" />
      </div>
      <div className="shipment-container">
        <div className="dispaly-center shipment-id-wrapper">
          <input
            id="shipment-id"
            data-testid="shipment-id"
            className="input-field"
            ref={shipmentIdFieldRef}
            value={shipmentId}
            onChange={onUpdateShipmentId}
            placeholder="Please enter the Shipment Id"
          />
        </div>
        <div className="dispaly-center separator-title">
          OR
        </div>
        <div className="dispaly-center trailer-id-wrapper">
          <input
            id="trailer-id"
            data-testid="trailer-id"
            className="input-field"
            ref={trailerIdFieldRef}
            value={trailerId}
            onChange={onUpdateTrailerId}
            placeholder="Please enter the Trailer Id"
          />
        </div>
        <div className="dispaly-center submit-container">
          <button data-testid="submit-button" type="submit" className={`btn ${shipmentId || trailerId ? 'btnActive' : 'btnDisabled'}`} onClick={onSubmit}>
            <span className="label">Submit</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TruckArrival;
