/**
 * @name getCookieValue
 * @param {String} key Key to find in the cookies
 * @returns {String | null} Value if found, otherwise null
 */
export const getCookieValue = (key) => {
  const cookies = document.cookie;
  if (!cookies) return null;
  const cookieKeyVal = cookies
    .split(';')
    .map((cookie) => cookie.trim())
    .find((string) => string.startsWith(key));
  return cookieKeyVal ? cookieKeyVal.trim().split('=')[1] : null;
};

/**
 * @name removeCookieKeyValue
 * @param {String} key Key to find and remove in the cookies
 * @returns {null}
 */
export const removeFromCookie = (key) => {
  if (!document.cookie) return null;
  document.cookie = `${key}=;path=/;expires=${new Date('01/01/2000').toUTCString()}`;
};

/**
 * @name getValueFromBrowserStorage
 * @param {String} key Key to find in session storage
 * @param {Object} storage sessionStorage | localStorage
 * @returns {String | null} Value if found, otherwise null
 */
export const getValueFromBrowserStorage = (key, storage = window.sessionStorage) => {
  if (!storage) return null;
  return storage.getItem(key) ? storage.getItem(key).trim() : null;
};

/**
 * @name setValueInBrowserStorage
 * @param {String} key Key to set in session storage
 * @param {any} value to map to the key in session storage
 * @param {Object} storage sessionStorage | localStorage
 * @param {Boolean} isString - is Value a plain string
 * @returns {null}
 */
export const setValueInBrowserStorage = (key, value, storage = window.sessionStorage, isString = false) => {
  if (!storage) return null;
  const stringifiedValue = JSON.stringify(value);
  if (key && value) {
    storage.setItem(key, isString ? value : stringifiedValue);
  }
};
