import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import './styles.css';

const RectangleLayout = (props) => {
  const {
    smallIcon,
    coverImage,
    titleText,
    renderSupportiveText,
    belowText,
  } = props;

  const defaultOptions = (animationData) => ({
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  return (
    <>
      <div className="column round-rectangle">
        <div className="row message-container">
          <div className="small-icon-container">
            <img src={smallIcon} alt="small-icon" />
          </div>
          <div className="column">
            <h3 className="roboto title-text">{titleText}</h3>
            <span className="roboto typography-regular subtitle-text">
              {renderSupportiveText()}
            </span>
          </div>
        </div>

        {coverImage?.animate ? (
          <Lottie
            style={{
              width: '15rem', height: '12rem', margin: '8%', alignSelf: 'center',
            }}
            options={defaultOptions(coverImage?.data)}
          />
        ) : <img src={coverImage?.data} className="large-icon" alt="large-icon" />}

      </div>
      {belowText && <b className="roboto typography-regular subtitle-text description">{belowText}</b>}
      {!!props.children && props.children}
    </>
  );
};

RectangleLayout.propTypes = {
  smallIcon: PropTypes.string.isRequired,
  coverImage: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  renderSupportiveText: PropTypes.func.isRequired,
  belowText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

RectangleLayout.defaultProps = {
  belowText: '',
  children: undefined,
};

export default RectangleLayout;
